import React from "react"
import { Trans, useTranslation, useI18next } from "gatsby-plugin-react-i18next"

import Layout from "../layouts/layout"
import SEO from "../layouts/seo"

const PrivacyPage = () => {
  const { language } = useI18next()
  const { t } = useTranslation()

  return (
    <Layout>
      <SEO title={t("footer.link-privacy")} />
      <article class="article legal">
        <h1 class="heading heading--md">
          <Trans>pp.header</Trans>
        </h1>

        <p>
          <Trans>pp.paragraph-1</Trans>
        </p>
        <ol>
          <li>
            <h3>
              <Trans>pp.item-1</Trans>
            </h3>
            <p>
              <Trans>pp.item-1-content-1</Trans>
              <br></br>
              <Trans>pp.item-1-content-1a</Trans>
            </p>
          </li>
          <li>
            <h3>
              <Trans>pp.item-2</Trans>
            </h3>
            <p>
              <Trans>pp.item-2-content-1</Trans>
            </p>
            <p>
              <ul>
                <li>
                  <Trans>pp.item-2-content-1-li-1</Trans>,
                </li>
                <li>
                  <Trans>pp.item-2-content-1-li-2</Trans>,
                </li>
                <li>
                  <Trans>pp.item-2-content-1-li-3</Trans>,
                </li>
                <li>
                  <Trans>pp.item-2-content-1-li-4</Trans>,
                </li>
                <li>
                  <Trans>pp.item-2-content-1-li-5</Trans>
                </li>
              </ul>
            </p>
            <p>
              <Trans>pp.item-2-content-2</Trans>
            </p>
            <p>
              <Trans>pp.item-2-content-3</Trans>
            </p>
            <p>
              <Trans>pp.item-2-content-4</Trans>
            </p>
          </li>
          <li>
            <h3>
              <Trans>pp.item-3</Trans>
            </h3>
            <p>
              <Trans>pp.item-3-content-1</Trans>
            </p>
            <p>
              <Trans>pp.item-3-content-2</Trans>
            </p>
            <p>
              <Trans>pp.item-3-content-3</Trans>
            </p>
            <p>
              <Trans>pp.item-3-content-4</Trans>
            </p>

            <div class="table-wrapper table-wrapper__scroll-x">
              <table class="table">
                <thead>
                  <tr>
                    <th>
                      <Trans>pp.item-3-content-5-th-1</Trans>
                    </th>
                    <th>
                      <Trans>pp.item-3-content-5-th-2</Trans>
                    </th>
                    <th>
                      <Trans>pp.item-3-content-5-th-3</Trans>
                    </th>
                    <th>
                      <Trans>pp.item-3-content-5-th-4</Trans>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <a
                        href="http://www.tiramizoo.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        www.tiramizoo.com
                      </a>
                    </td>
                    <td>_trmz_session</td>
                    <td>Session</td>
                    <td>
                      <Trans>pp.item-3-content-5-desc-1</Trans>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a
                        href="http://www.tiramizoo.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        www.tiramizoo.com
                      </a>
                    </td>
                    <td>cookie_eu_consented</td>
                    <td>12 months</td>
                    <td>
                      <Trans>pp.item-3-content-5-desc-2</Trans>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a
                        href="http://www.tiramizoo.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        www.tiramizoo.com
                      </a>
                    </td>
                    <td>PLL_language</td>
                    <td>12 months</td>
                    <td>
                      <Trans>pp.item-3-content-5-desc-3</Trans>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a
                        href="http://www.tiramizoo.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        www.tiramizoo.com
                      </a>
                    </td>
                    <td>cntcookie</td>
                    <td>unlimited</td>
                    <td>
                      <Trans>pp.item-3-content-5-desc-4</Trans>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </li>
          <li>
            <h3>
              <Trans>pp.item-4</Trans>
            </h3>
            <p>
              <Trans>pp.item-4-content-1</Trans>
            </p>
            <p>
              <Trans>pp.item-4-content-2</Trans>
            </p>
            <p>
              <Trans>pp.item-4-content-3</Trans>
            </p>
            <p>
              <Trans>pp.item-4-content-4</Trans>
            </p>
          </li>
          <li>
            <h3>
              <Trans>pp.item-5</Trans>
            </h3>
            <p>
              <Trans>pp.item-5-content-1</Trans>
            </p>
            <p>
              <Trans>pp.item-5-content-2</Trans>
            </p>
          </li>
          <li>
            <h3>
              <Trans>pp.item-6</Trans>
            </h3>
            <p>
              <Trans>pp.item-6-content-1</Trans>
            </p>
            <p>
              <Trans>pp.item-6-content-2</Trans>
            </p>
            <p>
              <Trans>pp.item-6-content-3</Trans>
            </p>
          </li>
          <li>
            <h3>
              <Trans>pp.item-7</Trans>
            </h3>
            <p>
              <Trans>pp.item-7-content-1</Trans>
            </p>
            <p>
              <Trans>pp.item-7-content-2</Trans>
            </p>
            <p>
              <Trans>pp.item-7-content-3</Trans>
            </p>
            <p>
              <Trans>pp.item-7-content-4</Trans>
            </p>
            <p>
              <Trans>pp.item-7-content-5</Trans>
            </p>
          </li>
          <li>
            <h3>
              <Trans>pp.item-8</Trans>
            </h3>
            <p>
              <Trans>pp.item-8-content-1</Trans>
            </p>
            <p>
              <Trans>pp.item-8-content-2</Trans>
            </p>
            <p>
              <Trans>pp.item-8-content-3</Trans>
            </p>
            <p>
              <Trans>pp.item-8-content-4</Trans>
            </p>
            <p>
              <Trans>pp.item-8-content-5</Trans>
            </p>
            <p>
              <Trans>pp.item-8-content-6</Trans>
            </p>
          </li>
          <li>
            <h3>
              <Trans>pp.item-9</Trans>
            </h3>
            <p>
              <Trans>pp.item-9-content-1</Trans>
            </p>
          </li>
          <li>
            <h3>
              <Trans>pp.item-10</Trans>
            </h3>
            <p>
              <Trans>pp.item-10-content-1</Trans>
            </p>
          </li>
          <li>
            <h3>
              <Trans>pp.item-11</Trans>
            </h3>
            <p>
              <Trans>pp.item-11-content-1</Trans>
            </p>
            <p>
              <Trans>pp.item-11-content-2</Trans>
            </p>
          </li>
          <li>
            <h3>
              <Trans>pp.item-12</Trans>
            </h3>
            <p>
              <Trans>pp.item-12-content-1</Trans>
            </p>
            <p>
              <Trans>pp.item-12-content-2</Trans>
            </p>
            <p>
              <Trans>pp.item-12-content-3</Trans>
            </p>
            <p>
              <Trans>pp.item-12-content-4</Trans>
            </p>
            <p>
              <Trans>pp.item-12-content-5</Trans>
            </p>
            <p>
              <Trans>pp.item-12-content-6</Trans>
            </p>
            <p>
              <Trans>pp.item-12-content-7</Trans>
            </p>
            <p>
              <Trans>pp.item-12-content-8</Trans>
            </p>
            <p>
              <Trans>pp.item-12-content-9</Trans>
            </p>
            <p>
              <Trans>pp.item-12-content-10</Trans>
            </p>
          </li>
          <li>
            <h3>
              <Trans>pp.item-13</Trans>
            </h3>
            <p>
              <Trans>pp.item-13-content-1</Trans>
            </p>
          </li>
          <li>
            <h3>
              <Trans>pp.item-14</Trans>
            </h3>
            <p>
              <Trans>pp.item-14-content-1</Trans>
            </p>
            <p>
              <Trans>pp.item-14-content-2</Trans>
            </p>
          </li>
        </ol>
      </article>
    </Layout>
  )
}
export default PrivacyPage
